import { render, staticRenderFns } from "./StudentCustomerTran.vue?vue&type=template&id=152aefc8&scoped=true&"
import script from "./StudentCustomerTran.vue?vue&type=script&lang=js&"
export * from "./StudentCustomerTran.vue?vue&type=script&lang=js&"
import style0 from "./StudentCustomerTran.vue?vue&type=style&index=0&id=152aefc8&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "152aefc8",
  null
  
)

export default component.exports